import React from "react"
import Helmet from "react-helmet"
import SEO from "../components/seo"

import "../scss/follow-us.scss"

const BlogPage = () => {
  return (
    <div> 
      <SEO title="Follow us" keywords={[`youpal`, `youpal group`, `social`]} />
      <Helmet>
        <script src="https://assets.juicer.io/embed.js" type="text/javascript"></script>
        <link href="https://assets.juicer.io/embed.css" media="all" rel="stylesheet" type="text/css" />
      </Helmet>
      <section className="container">
        <div className="socialContainer">
          <div className="juicerDiv">
            <ul className="juicer-feed" data-feed-id="uteckie"><h1 className="referral"><a href="https://www.juicer.io">Powered by Juicer.io</a></h1></ul>
          </div>
          <div className="linksDiv">
            <h4>Social Media Links</h4>
            <div className="socialDiv">
              <h5>Facebook</h5>
              <div>
                <a href="https://www.facebook.com/youpalgroup" target="_blank">Youpal Group Facebook</a>
                <a href="https://www.facebook.com/yougig.work" target="_blank">Yougig Facebook</a>
                <a href="https://www.facebook.com/YouCloud-106717068040867" target="_blank">Youcloud Facebook</a>
                <a href="https://www.facebook.com/YouSchool-106012968119030" target="_blank">Youschool Facebook</a>
                <a href="https://www.facebook.com/YouMedico-103346685053688" target="_blank">Youmedico Facebook</a>
                <a href="https://www.facebook.com/Youadvisors-102882168435236" target="_blank">Youradvisors Facebook</a>
                <a href="https://www.facebook.com/UTeckie-101724755224310" target="_blank">UTeckie Facebook</a>
              </div>
            </div>
            <div className="socialDiv">
              <h5>Instagram</h5>
              <div>
                <a href="https://www.instagram.com/youpalgroup/" target="_blank">Youpal Group Insta</a>
                <a href="https://www.instagram.com/youpal.advisors/" target="_blank">Youradvisors Insta</a>
                <a href="https://www.instagram.com/youpal.cloud/" target="_blank">Youcloud Insta</a>
                <a href="https://www.instagram.com/youschool/" target="_blank">Youschool Insta</a>
                <a href="https://www.instagram.com/yougig.work/" target="_blank">Yougig Insta</a>
                <a href="https://www.instagram.com/youmedico/" target="_blank">Youmedico Insta</a>
                <a href="https://www.instagram.com/u.teckie/" target="_blank">Uteckie Insta</a>
              </div>
            </div>
            <div className="socialDiv">
              <h5>Twitter</h5>
              <div>
                <a href="https://twitter.com/YoupalGroup" target="_blank">Youpal Group Twitter</a>
                <a href="https://twitter.com/YouMedico" target="_blank">Youmedico Twitter</a>
                <a href="https://twitter.com/YouSchool3" target="_blank">Youschool Twitter</a>
                <a href="https://twitter.com/YouCloud5" target="_blank">Youcloud Twitter</a>
                <a href="https://twitter.com/you_advisor" target="_blank">Youradvisors Twitter</a>
                <a href="https://twitter.com/yougig2" target="_blank">Yougig Twitter</a>
                <a href="https://twitter.com/TeckieU" target="_blank">Uteckie Twitter</a>
              </div>
            </div>
            <div className="socialDiv">
              <h5>LinkedIn</h5>
              <div>
                <a href="https://www.linkedin.com/company/youpalgroup/?viewAsMember=true" target="_blank">Youpal Group LinkedIn</a>
                <a href="https://www.linkedin.com/showcase/youadvisors/?viewAsMember=true" target="_blank">Youradvisors LinkedIn</a>
                <a href="https://www.linkedin.com/showcase/uteckie/?viewAsMember=true" target="_blank">Uteckie LinkedIn</a>
                <a href="https://www.linkedin.com/showcase/youpal-cloud/?viewAsMember=true" target="_blank">Youcloud LinkedIn</a>
                <a href="https://www.linkedin.com/showcase/you-medico/?viewAsMember=true" target="_blank">Youmedico LinkedIn</a>
                <a href="https://www.linkedin.com/showcase/you-gig/?viewAsMember=true" target="_blank">Yougig LinkedIn</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default BlogPage
